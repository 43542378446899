.content {
  padding-top: 60px;
}

@media screen and (max-width: 576px) {
  .content {
    padding-top: 0px;
  }
}

